import {
  InformationCircleIcon,
  ChartBarIcon,
  SunIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
// import { InitGame } from './components/modals/InitGame'
import Countdown from 'react-countdown'

import {
  WORDLE_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  NOT_FOUND_MESSAGE,
  OVER_TIME_MESSAGE,
} from './constants/strings'
import { isWordInWordList, isWinningWord, solution } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  // saveGameStateToLocalStorage,
} from './lib/localStorage'

import './App.css'

const ALERT_TIME_MS = 2000

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  // const URL = "https://api-disfrutones-staging.mallmark.es/api/ThirdParty/SetMkActionParticipation";
  const URL = "https://api-disfrutones.mallmark.es/api/ThirdParty/SetMkActionParticipation";
  const search = window.location.search;
  const customerEmail = new URLSearchParams(search).get('customerEmail');
  const mkActionId = new URLSearchParams(search).get('mkActionId');
  const token = new URLSearchParams(search).get('token');
  const request = () => {
    var data = {
      'mkActionId': mkActionId
    }
    fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }).then(function (response) {
      console.log(response)
    })
      .catch(function (error) {
        console.log(error)
      });
  }

  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  // const [isInitGameModalOpen, setInitGameModalOpen] = useState(true)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isRunning, setRunning] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isTimeOver, setIsTimeOver] = useState(false)
  const [currentTime, setCurrentTime] = useState(180000)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    // saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isTimeOver) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost, isTimeOver])

  const onChar = (value: string) => {
    // if (!isInitGameModalOpen && !isTimeOver) {
    if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
    // }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {

      return
    }
    if (!(currentGuess.length === 5)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    const winningWord = isWinningWord(currentGuess)

    if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        request();
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        request();
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
      }
    }
  }


  if (customerEmail == undefined || mkActionId == undefined || token == undefined) {
    return (<div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8 flex-col flex justify-center h-screen">
      <div className="flex w-80 mx-auto items-center mb-8 mt-12">
        <h1>Lo sentimos pero esto es una acción para disfrutones y que se dirija hacia el APP o la Web de Socio</h1>
      </div>
    </div>
    )
  } else {

    return (
      <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8 flex-col flex justify-center h-screen">
        <div className="flex w-80 mx-auto items-end justify-between mb-8">
          <img src="./logo.svg" alt="" />
          <InformationCircleIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <Grid guesses={guesses} currentGuess={currentGuess} />
        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          guesses={guesses}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleClose={() => window.location.reload()}
          guesses={guesses}
          gameStats={stats}
          isGameLost={isGameLost}
          isGameWon={isGameWon}
          handleShare={() => {
            setSuccessAlert(GAME_COPIED_MESSAGE)
            return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
          }}
        />
        <AboutModal
          isOpen={isAboutModalOpen}
          handleClose={() => setIsAboutModalOpen(false)}
        />

        <Alert
          message={NOT_ENOUGH_LETTERS_MESSAGE}
          message2="Debe de haber 5 letras para continuar"
          isOpen={isNotEnoughLetters}
        />
        <Alert
          message2="Inténtalo con otra"
          message={WORD_NOT_FOUND_MESSAGE}
          isOpen={isWordNotFoundAlertOpen}
        />
        <Alert
          message={CORRECT_WORD_MESSAGE(solution)}
          message2={NOT_FOUND_MESSAGE}
          isOpen={isGameLost}
        />
        <Alert
          message={CORRECT_WORD_MESSAGE(solution)}
          message2={OVER_TIME_MESSAGE}
          isOpen={isTimeOver}
        />
        <Alert
          message={'¡Enhorabuena!'}
          message2={WIN_MESSAGES}
          isOpen={isGameWon}
          variant="success"
        />
      </div>
    )
  }
}

export default App
