export const WORDS = [
  'ABACO',
  'ABAJO',
  'ABEJA',
  'ABETO',
  'ABRIL',
  'ABUSO',
  'ACARO',
  'ACASO',
  'ACEBO',
  'ACERO',
  'ACHIS',
  'ACIAL',
  'ACIDA',
  'ACIDO',
  'ACOSO',
  'ACTOR',
  'ACUSE',
  'ADIAR',
  'ADIOS',
  'ADOBO',
  'AEREA',
  'AEREO',
  'AFEAR',
  'AGAPE',
  'AGATA',
  'AGAVE',
  'AGRIA',
  'AGRIO',
  'AGUDA',
  'AGUDO',
  'AGUJA',
  'AHOGO',
  'AHORA',
  'AIRAR',
  'AJADA',
  'AJADO',
  'AJENA',
  'AJENO',
  'AJETE',
  'ALADA',
  'ALADO',
  'ALBOR',
  'ALBUM',
  'ALDEA',
  'ALEAR',
  'ALERO',
  'ALETA',
  'ALFAR',
  'ALFIL',
  'ALFOZ',
  'ALGUN',
  'ALIAR',
  'ALIAS',
  'ALIJO',
  'ALIÑO',
  'ALJEZ',
  'ALTAR',
  'ALZAR',
  'AMADO',
  'AMAÑO',
  'AMBAR',
  'AMBON',
  'AMEBA',
  'AMENA',
  'AMENO',
  'AMIGA',
  'AMIGO',
  'AMITO',
  'AMPON',
  'ANACO',
  'ANADE',
  'ANAFE',
  'ANATA',
  'ANCHA',
  'ANCHO',
  'ANCLA',
  'ANDAR',
  'ANDEL',
  'ANEXA',
  'ANEXO',
  'ANGEL',
  'ANIME',
  'ANIMO',
  'ANODO',
  'ANSIA',
  'ANTES',
  'ANTRO',
  'ANUAL',
  'AÑADA',
  'AÑEJA',
  'AÑEJO',
  'AÑOJO',
  'AORTA',
  'APAÑO',
  'APEAR',
  'APEGO',
  'APERO',
  'APICE',
  'APNEA',
  'APODO',
  'APOLO',
  'APOYO',
  'APURO',
  'AQUEL',
  'ARADO',
  'ARANA',
  'ARAÑA',
  'ARBOL',
  'ARCHA',
  'ARCON',
  'ARDER',
  'ARDOR',
  'ARDUA',
  'ARDUO',
  'ARENA',
  'AREPA',
  'ARGON',
  'ARGOS',
  'ARGOT',
  'ARIDA',
  'ARIDO',
  'ARIES',
  'ARMAR',
  'ARNES',
  'AROMA',
  'ARPAR',
  'ARPIA',
  'ARPON',
  'ARRAS',
  'ARREO',
  'ARROZ',
  'ARZON',
  'ASADA',
  'ASADO',
  'ASCUA',
  'ASEAR',
  'ASILO',
  'ASOMO',
  'ASPID',
  'ASTIL',
  'ASTRO',
  'ATADA',
  'ATADO',
  'ATAJO',
  'ATAUD',
  'ATICO',
  'ATLAS',
  'ATONA',
  'ATONO',
  'ATRAS',
  'ATRIL',
  'ATRIO',
  'ATROZ',
  'AUDAZ',
  'AUDIO',
  'AUNAR',
  'AUPAR',
  'AVION',
  'AVISO',
  'AXILA',
  'AYUNO',
  'AZOTE',
  'BABLE',
  'BABOR',
  'BACHE',
  'BACIN',
  'BACON',
  'BADEN',
  'BADIL',
  'BAFLE',
  'BAHIA',
  'BAILE',
  'BAJAR',
  'BAJEL',
  'BAJON',
  'BALAR',
  'BALDA',
  'BALDE',
  'BALIN',
  'BALON',
  'BALSA',
  'BAMBA',
  'BAMBU',
  'BANAL',
  'BANCA',
  'BANCO',
  'BANDA',
  'BANDO',
  'BANJO',
  'BAÑAR',
  'BAQUE',
  'BARBA',
  'BARBO',
  'BARCA',
  'BARCO',
  'BARDO',
  'BARIO',
  'BARON',
  'BARRA',
  'BASAL',
  'BASAR',
  'BASTO',
  'BATEA',
  'BATIN',
  'BATIR',
  'BATON',
  'BAZAR',
  'BEATA',
  'BEATO',
  'BEBER',
  'BECAR',
  'BEDEL',
  'BEFAR',
  'BEIGE',
  'BELEN',
  'BELFO',
  'BELGA',
  'BELLA',
  'BELLO',
  'BEMOL',
  'BEODA',
  'BEODO',
  'BERRO',
  'BERZA',
  'BESAR',
  'BETUN',
  'BICAL',
  'BICHA',
  'BICHO',
  'BIDON',
  'BIELA',
  'BILIS',
  'BILLA',
  'BINAR',
  'BINGO',
  'BINZA',
  'BIOTA',
  'BISAR',
  'BISEL',
  'BIZCA',
  'BIZCO',
  'BIZNA',
  'BLEDO',
  'BLUES',
  'BLUSA',
  'BOATO',
  'BOCIO',
  'BOCOY',
  'BOFIA',
  'BOHIO',
  'BOINA',
  'BOLLA',
  'BOLLO',
  'BOLSA',
  'BOLSO',
  'BOMBA',
  'BOMBO',
  'BONGO',
  'BONZO',
  'BORAX',
  'BORDE',
  'BORDO',
  'BORLA',
  'BORNE',
  'BOSON',
  'BOSTA',
  'BOTAR',
  'BOTIN',
  'BOTON',
  'BOXEO',
  'BOXER',
  'BOZAL',
  'BRAGA',
  'BRASA',
  'BRAVA',
  'BRAVO',
  'BRAZA',
  'BRAZO',
  'BREGA',
  'BREÑA',
  'BRETE',
  'BREVA',
  'BREVE',
  'BRIAL',
  'BRIDA',
  'BRISA',
  'BROCA',
  'BROMA',
  'BROTE',
  'BROZA',
  'BRUJA',
  'BRUJO',
  'BRUMA',
  'BRUTA',
  'BRUTO',
  'BUCAL',
  'BUCEO',
  'BUCHE',
  'BUCLE',
  'BUENA',
  'BUENO',
  'BUFAR',
  'BUFET',
  'BUFON',
  'BUGLE',
  'BUJIA',
  'BULBO',
  'BULLA',
  'BULTO',
  'BUQUE',
  'BURDA',
  'BURDO',
  'BURGO',
  'BURLA',
  'BURRA',
  'BURRO',
  'BUSTO',
  'BUZON',
  'CABAL',
  'CABER',
  'CABLE',
  'CABRA',
  'CACAO',
  'CACHE',
  'CACHO',
  'CAFRE',
  'CAGAR',
  'CAGON',
  'CAIDA',
  'CAIDO',
  'CAJON',
  'CALAR',
  'CALCO',
  'CALDO',
  'CALIZ',
  'CALLO',
  'CALMA',
  'CALON',
  'CALOR',
  'CALVA',
  'CALVO',
  'CALZA',
  'CALZO',
  'CAMPO',
  'CANAL',
  'CANJE',
  'CANOA',
  'CANON',
  'CANTE',
  'CANTO',
  'CAÑAR',
  'CAÑON',
  'CAOBA',
  'CAPAR',
  'CAPAZ',
  'CAPON',
  'CAQUI',
  'CARAY',
  'CARCA',
  'CARDO',
  'CAREO',
  'CARGA',
  'CARGO',
  'CARIZ',
  'CARNE',
  'CARON',
  'CARPA',
  'CARTA',
  'CASAL',
  'CASAR',
  'CASCO',
  'CASPA',
  'CASTA',
  'CASTO',
  'CATAR',
  'CATON',
  'CATRE',
  'CAUCE',
  'CAUSA',
  'CAUTA',
  'CAUTO',
  'CAVAR',
  'CAZAR',
  'CAZON',
  'CEBAR',
  'CEBRA',
  'CECEO',
  'CEDER',
  'CEDRO',
  'CEGAR',
  'CEJAR',
  'CELAR',
  'CELDA',
  'CELIA',
  'CELTA',
  'CENAR',
  'CENSO',
  'CEÑIR',
  'CERCA',
  'CERCO',
  'CERDA',
  'CERDO',
  'CERNE',
  'CERRO',
  'CESAR',
  'CESIO',
  'CESTA',
  'CESTO',
  'CETME',
  'CETRO',
  'CEUTI',
  'CHAPA',
  'CHATA',
  'CHATO',
  'CHECA',
  'CHECO',
  'CHELO',
  'CHEPA',
  'CHICA',
  'CHICO',
  'CHILE',
  'CHINA',
  'CHINO',
  'CHIVA',
  'CHIVO',
  'CHOCO',
  'CHOPO',
  'CHOTO',
  'CHOZA',
  'CHUFA',
  'CHULA',
  'CHULO',
  'CHUPA',
  'CHUTE',
  'CHUZO',
  'CICLO',
  'CIEGA',
  'CIEGO',
  'CIELO',
  'CIENO',
  'CIFRA',
  'CINCO',
  'CINTA',
  'CIRCO',
  'CIRIO',
  'CIRRO',
  'CISCO',
  'CISMA',
  'CISNE',
  'CITAR',
  'CIVIL',
  'CLARA',
  'CLARO',
  'CLASE',
  'CLAVE',
  'CLAVO',
  'CLERO',
  'CLIMA',
  'CLORO',
  'COBRE',
  'COBRO',
  'COCER',
  'COCHO',
  'COFIA',
  'COFRE',
  'COGER',
  'COITO',
  'COJIN',
  'COLAR',
  'COLIN',
  'COLMO',
  'COLON',
  'COLOR',
  'COLZA',
  'COMBA',
  'COMBO',
  'COMER',
  'COMIC',
  'COMUN',
  'CONDE',
  'CONGA',
  'COÑAC',
  'COPAR',
  'COPIA',
  'COPLA',
  'COPON',
  'COQUE',
  'CORAL',
  'CORAN',
  'CORRO',
  'CORSE',
  'CORTA',
  'CORTE',
  'CORTO',
  'CORVA',
  'CORZO',
  'COSER',
  'COSTA',
  'COSTE',
  'COSTO',
  'COXAL',
  'COXIS',
  'CRASO',
  'CREAR',
  'CREDO',
  'CREER',
  'CREMA',
  'CRIAR',
  'CRIBA',
  'CROAR',
  'CROMA',
  'CROMO',
  'CRONO',
  'CROSS',
  'CRUCE',
  'CRUDA',
  'CRUDO',
  'CRUEL',
  'CUAJO',
  'CUERO',
  'CUEVA',
  'CUITA',
  'CULAR',
  'CULPA',
  'CULTA',
  'CULTO',
  'CUÑAR',
  'CUOTA',
  'CUPLE',
  'CUPON',
  'CURAR',
  'CURDA',
  'CURIA',
  'CURRO',
  'CURRY',
  'CURSI',
  'CURSO',
  'CURVA',
  'CURVO',
  'CUTER',
  'CUTIS',
  'DABLE',
  'DACHA',
  'DADOR',
  'DAMAS',
  'DANDI',
  'DANZA',
  'DAÑAR',
  'DARDO',
  'DATAR',
  'DATIL',
  'DEBER',
  'DEBIL',
  'DEBUT',
  'DECIR',
  'DEDAL',
  'DEJAR',
  'DELCO',
  'DELTA',
  'DEMAS',
  'DENSA',
  'DENSO',
  'DERBI',
  'DESDE',
  'DESEO',
  'DEUDA',
  'DIADA',
  'DIANA',
  'DIAÑO',
  'DICHA',
  'DICHO',
  'DIETA',
  'DIGNA',
  'DIGNO',
  'DINAR',
  'DIÑAR',
  'DIODO',
  'DIOSA',
  'DIQUE',
  'DISCO',
  'DIVAN',
  'DOBLE',
  'DOCIL',
  'DOCTO',
  'DOGAL',
  'DOGMA',
  'DOLAR',
  'DOLER',
  'DOLOR',
  'DOMAR',
  'DONAR',
  'DONDE',
  'DOPAR',
  'DORAR',
  'DORNA',
  'DORSO',
  'DOSEL',
  'DOSIS',
  'DOTAR',
  'DRAMA',
  'DROGA',
  'DUCAL',
  'DUCHA',
  'DUCHO',
  'DUDAR',
  'DUELO',
  'DUEÑA',
  'DUEÑO',
  'DUETO',
  'DULCE',
  'DUPLA',
  'DUPLO',
  'DUQUE',
  'DURAR',
  'EBANO',
  'EBRIA',
  'EBRIO',
  'ECHAR',
  'EDEMA',
  'EDILA',
  'EFEBO',
  'EJIDO',
  'ELATA',
  'ELATO',
  'ELEPE',
  'ELITE',
  'ELLOS',
  'ENANA',
  'ENANO',
  'ENCIA',
  'ENEMA',
  'ENOJO',
  'ENTRE',
  'ENVES',
  'ENVIO',
  'EPICA',
  'EPICO',
  'EPOCA',
  'EQUIS',
  'EREBO',
  'ERIAL',
  'ERIZO',
  'ERRAR',
  'ERROR',
  'ESCAY',
  'ESNOB',
  'ESPIA',
  'ESQUI',
  'ESTAR',
  'ESTIO',
  'ESTOR',
  'ETAPA',
  'ETICA',
  'ETICO',
  'ETIMO',
  'ETNIA',
  'EXITO',
  'EXODO',
  'EXTRA',
  'FACHA',
  'FACIL',
  'FACTO',
  'FAENA',
  'FAGOT',
  'FAJAR',
  'FAJIN',
  'FALAZ',
  'FALDA',
  'FALLA',
  'FALLO',
  'FALSA',
  'FALSO',
  'FALTA',
  'FANAL',
  'FANGO',
  'FAÑAR',
  'FARDO',
  'FARIA',
  'FAROL',
  'FARON',
  'FARRA',
  'FARSA',
  'FASTO',
  'FATAL',
  'FATUO',
  'FAUNA',
  'FAUNO',
  'FAVOR',
  'FEBLE',
  'FECAL',
  'FECHA',
  'FELIZ',
  'FELON',
  'FELPA',
  'FEMUR',
  'FENDA',
  'FENIX',
  'FERIA',
  'FEROZ',
  'FERRY',
  'FETAL',
  'FETEN',
  'FEUDO',
  'FIADO',
  'FIBRA',
  'FICHA',
  'FICUS',
  'FIDEO',
  'FIERA',
  'FIERO',
  'FIGLE',
  'FIGON',
  'FIJAR',
  'FILAR',
  'FILIA',
  'FILON',
  'FINAL',
  'FINAR',
  'FINCA',
  'FINTA',
  'FIRMA',
  'FIRME',
  'FISCO',
  'FLACA',
  'FLACO',
  'FLAMA',
  'FLASH',
  'FLATO',
  'FLECO',
  'FLEJE',
  'FLEMA',
  'FLETE',
  'FLEXO',
  'FLIPE',
  'FLOJO',
  'FLORA',
  'FLOTA',
  'FLUIR',
  'FLUJO',
  'FLUOR',
  'FOBIA',
  'FOCAL',
  'FOGON',
  'FOLGO',
  'FOLIO',
  'FONDA',
  'FONDO',
  'FORAL',
  'FORJA',
  'FORMA',
  'FORRO',
  'FORUM',
  'FOSCA',
  'FOSCO',
  'FOSIL',
  'FOTON',
  'FRASE',
  'FREIR',
  'FRENO',
  'FRESA',
  'FRISO',
  'FRITA',
  'FRITO',
  'FRUTA',
  'FRUTO',
  'FUEGO',
  'FUERA',
  'FUERO',
  'FUGAR',
  'FUGAZ',
  'FULAR',
  'FUMAR',
  'FUNDA',
  'FURIA',
  'FUROR',
  'FUSIL',
  'FUSTA',
  'FUSTE',
  'FUTIL',
  'GABAN',
  'GACHA',
  'GAFAR',
  'GAFAS',
  'GAITA',
  'GALAN',
  'GALGA',
  'GALGO',
  'GALIO',
  'GALLO',
  'GALON',
  'GAMBA',
  'GAMMA',
  'GANAR',
  'GANGA',
  'GANSO',
  'GAÑAN',
  'GAÑIR',
  'GARBO',
  'GARRA',
  'GARZA',
  'GASTO',
  'GATEO',
  'GAUSS',
  'GAVIA',
  'GAYAR',
  'GEMIR',
  'GENIO',
  'GENTE',
  'GEODA',
  'GESTA',
  'GESTO',
  'GIBON',
  'GIRAR',
  'GISTE',
  'GLASE',
  'GLEBA',
  'GLOBO',
  'GNOMO',
  'GOCHO',
  'GOFIO',
  'GOFRE',
  'GOLFA',
  'GOLFO',
  'GOLPE',
  'GORDA',
  'GORDO',
  'GORGA',
  'GORJA',
  'GORRA',
  'GORRO',
  'GOTEO',
  'GOZAR',
  'GOZNE',
  'GRADA',
  'GRADO',
  'GRAFO',
  'GRAJO',
  'GRAMA',
  'GRAMO',
  'GRANA',
  'GRANO',
  'GRAPA',
  'GRASA',
  'GRASO',
  'GRATA',
  'GRATO',
  'GRAVA',
  'GRAVE',
  'GRECA',
  'GREEN',
  'GRELO',
  'GREÑA',
  'GRIAL',
  'GRIFO',
  'GRILL',
  'GRIMA',
  'GRIPE',
  'GRISU',
  'GRITO',
  'GROMO',
  'GROSO',
  'GRUMO',
  'GRUPA',
  'GRUPI',
  'GRUPO',
  'GRUTA',
  'GUAJE',
  'GUAPA',
  'GUAPO',
  'GUASA',
  'GUATA',
  'GUBIA',
  'GUETO',
  'GUIAR',
  'GUIÑO',
  'GUION',
  'GUIRI',
  'GUISO',
  'GUITA',
  'GULAG',
  'GUSTO',
  'HABAR',
  'HABER',
  'HABIL',
  'HABLA',
  'HACER',
  'HACHA',
  'HACHE',
  'HACIA',
  'HAMPA',
  'HAREN',
  'HARTO',
  'HASTA',
  'HAYAL',
  'HEBRA',
  'HECHA',
  'HECHO',
  'HEDER',
  'HEDOR',
  'HELAR',
  'HELAS',
  'HELIO',
  'HENAL',
  'HENAR',
  'HEÑIR',
  'HERIR',
  'HEROE',
  'HERPE',
  'HERTZ',
  'HIATO',
  'HIELO',
  'HIENA',
  'HILAR',
  'HIMEN',
  'HIMNO',
  'HINDU',
  'HIPAR',
  'HIPER',
  'HOBBY',
  'HOGAR',
  'HONDA',
  'HONDO',
  'HONGO',
  'HONOR',
  'HONRA',
  'HORCA',
  'HORMA',
  'HORNO',
  'HOSCA',
  'HOSCO',
  'HOTEL',
  'HUCHA',
  'HUECA',
  'HUECO',
  'HUESO',
  'HUEVA',
  'HUEVO',
  'HUIDA',
  'HUIDO',
  'HUMOR',
  'HUMUS',
  'HURON',
  'HURRA',
  'HURTO',
  'IBERA',
  'IBERO',
  'ICONO',
  'ICTUS',
  'IDEAL',
  'IDEAR',
  'IDOLO',
  'IGNEO',
  'IGUAL',
  'IGUAR',
  'IJADA',
  'ILESA',
  'ILESO',
  'ILION',
  'ILUSA',
  'ILUSO',
  'IMPAR',
  'IMPIA',
  'IMPIO',
  'INANE',
  'INDIA',
  'INDIO',
  'INPUT',
  'IRANI',
  'ISLAM',
  'ISTMO',
  'IZADA',
  'JABON',
  'JADEO',
  'JAIMA',
  'JALAR',
  'JALEA',
  'JALEO',
  'JAMON',
  'JAQUE',
  'JARRA',
  'JARRO',
  'JASPE',
  'JAUJA',
  'JAULA',
  'JEQUE',
  'JERGA',
  'JIBIA',
  'JIRON',
  'JONDO',
  'JOVEN',
  'JUBON',
  'JUDAS',
  'JUDIA',
  'JUDIO',
  'JUEGO',
  'JUEZA',
  'JUGAR',
  'JULIO',
  'JUNIO',
  'JUNTA',
  'JUNTO',
  'JURAR',
  'JUREL',
  'JUSTA',
  'JUSTO',
  'LABIA',
  'LABIO',
  'LABOR',
  'LACAR',
  'LACIO',
  'LACON',
  'LACRA',
  'LACRE',
  'LAGAR',
  'LAICA',
  'LAICO',
  'LAMER',
  'LANAR',
  'LANCE',
  'LANZA',
  'LAPIZ',
  'LAPON',
  'LAPSO',
  'LARGA',
  'LARGO',
  'LARVA',
  'LASCA',
  'LASER',
  'LATEX',
  'LATIN',
  'LATIR',
  'LATON',
  'LAUDE',
  'LAVAR',
  'LAXAR',
  'LAZAR',
  'LECHE',
  'LECHO',
  'LEGAL',
  'LEGAR',
  'LEGUA',
  'LEIDA',
  'LEIDO',
  'LEJIA',
  'LEJOS',
  'LEMUR',
  'LENTA',
  'LENTE',
  'LENTO',
  'LEONA',
  'LEPRA',
  'LERDA',
  'LERDO',
  'LETAL',
  'LETON',
  'LETRA',
  'LEVAR',
  'LIADA',
  'LIADO',
  'LIANA',
  'LIARA',
  'LIBAR',
  'LIBIA',
  'LIBIO',
  'LIBRA',
  'LIBRE',
  'LIBRO',
  'LICEO',
  'LICOR',
  'LIDIA',
  'LIGAR',
  'LIGHT',
  'LIGON',
  'LIGUE',
  'LIJAR',
  'LIMAR',
  'LIMBO',
  'LIMON',
  'LINAR',
  'LINCE',
  'LINDA',
  'LINDE',
  'LINDO',
  'LINEA',
  'LINFA',
  'LINIO',
  'LIOSA',
  'LIOSO',
  'LIRIO',
  'LIRON',
  'LISTA',
  'LISTO',
  'LITIO',
  'LITRO',
  'LIVOR',
  'LLAGA',
  'LLAMA',
  'LLANA',
  'LLANO',
  'LLAVE',
  'LLENA',
  'LLENO',
  'LLORO',
  'LOADO',
  'LOBBY',
  'LOCAL',
  'LOGIA',
  'LOGRO',
  'LOMBA',
  'LONJA',
  'LORZA',
  'LUCHA',
  'LUCIR',
  'LUCRO',
  'LUDIR',
  'LUEGO',
  'LUGAR',
  'LUGRE',
  'LUMEN',
  'LUNAR',
  'LUPUS',
  'LUXAR',
  'MACHO',
  'MACRO',
  'MADOR',
  'MADRE',
  'MAFIA',
  'MAGIA',
  'MAGMA',
  'MAGNA',
  'MAGNO',
  'MAGRO',
  'MAJAR',
  'MALAR',
  'MALLA',
  'MALLO',
  'MALTA',
  'MALVA',
  'MAMAR',
  'MAMBO',
  'MAMUT',
  'MANAR',
  'MANCO',
  'MANDO',
  'MANGA',
  'MANGO',
  'MANIA',
  'MANIR',
  'MANSA',
  'MANSO',
  'MANTA',
  'MANTO',
  'MAORI',
  'MAPEO',
  'MARCA',
  'MARCO',
  'MAREA',
  'MAREO',
  'MARTA',
  'MARTE',
  'MARZO',
  'MASIA',
  'MASON',
  'MATAR',
  'MATIZ',
  'MAYOR',
  'MAZAR',
  'MEADA',
  'MECER',
  'MECHA',
  'MEDIA',
  'MEDIR',
  'MEJOR',
  'MELON',
  'MELVA',
  'MENDA',
  'MENEO',
  'MENOR',
  'MENOS',
  'MENTA',
  'MENTE',
  'MEONA',
  'MERMA',
  'MESAR',
  'MESON',
  'METAL',
  'METER',
  'METRO',
  'MICRA',
  'MICRO',
  'MIGAR',
  'MIMAR',
  'MINAR',
  'MINIO',
  'MINUE',
  'MIOMA',
  'MIOPE',
  'MIRAR',
  'MIRLO',
  'MIRON',
  'MIRRA',
  'MIRTO',
  'MISAL',
  'MISIL',
  'MISMA',
  'MISMO',
  'MITAD',
  'MITIN',
  'MITRA',
  'MIURA',
  'MIXTA',
  'MIXTO',
  'MOCHO',
  'MODAL',
  'MODEM',
  'MOFAR',
  'MOHIN',
  'MOJAR',
  'MOJON',
  'MOLAR',
  'MOLDE',
  'MOLER',
  'MOMIA',
  'MONDA',
  'MONJA',
  'MONJE',
  'MONTA',
  'MONTE',
  'MONTO',
  'MORAL',
  'MORAR',
  'MORBO',
  'MORIR',
  'MORRO',
  'MORSA',
  'MORSE',
  'MOSCA',
  'MOSTO',
  'MOTEL',
  'MOTIN',
  'MOTOR',
  'MOVER',
  'MOVIL',
  'MUCHA',
  'MUCHO',
  'MUDAR',
  'MUECA',
  'MUELA',
  'MUESO',
  'MUGAR',
  'MUGIR',
  'MUGRE',
  'MUJER',
  'MUJOL',
  'MULAR',
  'MULTA',
  'MUNDO',
  'MUÑIR',
  'MUÑON',
  'MURAL',
  'MURGA',
  'MUSEO',
  'MUSGO',
  'MUSLO',
  'MUTAR',
  'MUTIS',
  'MUTUA',
  'MUTUO',
  'NABLA',
  'NACAR',
  'NACER',
  'NACHO',
  'NADAR',
  'NADIE',
  'NADIR',
  'NAIPE',
  'NALGA',
  'NARCO',
  'NARDO',
  'NARIZ',
  'NASAL',
  'NATAL',
  'NAVAL',
  'NAVIO',
  'NECIA',
  'NECIO',
  'NEGAR',
  'NEGRA',
  'NEGRO',
  'NEVAR',
  'NICHO',
  'NIETA',
  'NIETO',
  'NIEVE',
  'NIMIA',
  'NIMIO',
  'NINFA',
  'NINOT',
  'NIÑEZ',
  'NIPON',
  'NIQUI',
  'NITRO',
  'NIVEL',
  'NIVEO',
  'NOBLE',
  'NOCHE',
  'NODAL',
  'NOGAL',
  'NORIA',
  'NORMA',
  'NORTE',
  'NOTAR',
  'NOVAL',
  'NOVEL',
  'NOVIA',
  'NOVIO',
  'NUERA',
  'NUEVA',
  'NUEVE',
  'NUEVO',
  'NUNCA',
  'ÑANDU',
  'ÑOÑEZ',
  'ÑOQUI',
  'OASIS',
  'OBESA',
  'OBESO',
  'OBICE',
  'OBITO',
  'OBLEA',
  'OBRAR',
  'OBVIA',
  'OBVIO',
  'OCASO',
  'ODEON',
  'ODIAR',
  'OESTE',
  'OHMIO',
  'OIDOR',
  'OJALA',
  'OJEAR',
  'OJERA',
  'OJETE',
  'OJOSO',
  'OLEAR',
  'OLIVA',
  'OLIVO',
  'OLLAR',
  'OMEGA',
  'OMISO',
  'OPACA',
  'OPACO',
  'OPALO',
  'OPERA',
  'OPTAR',
  'OREAR',
  'OREJA',
  'ORGIA',
  'ORINA',
  'ORLAR',
  'ORUGA',
  'ORUJO',
  'OSADA',
  'OSADO',
  'OSERA',
  'OSMIO',
  'OSTIA',
  'OSTRA',
  'OTEAR',
  'OTERO',
  'OTOÑO',
  'OVALO',
  'OVEJA',
  'OVIDO',
  'OVINA',
  'OVINO',
  'OVULO',
  'OXIDO',
  'OZONO',
  'PACER',
  'PACTO',
  'PADRE',
  'PAGAR',
  'PAJAR',
  'PALCO',
  'PALIO',
  'PALMA',
  'PALMO',
  'PANDA',
  'PANEL',
  'PANGA',
  'PANZA',
  'PAÑAL',
  'PAPAR',
  'PAPEL',
  'PARAR',
  'PARCA',
  'PARCO',
  'PARDA',
  'PARDO',
  'PARED',
  'PAREO',
  'PARGO',
  'PARIA',
  'PARIR',
  'PARNE',
  'PARRA',
  'PARTE',
  'PARTO',
  'PASAR',
  'PASEO',
  'PASMA',
  'PASMO',
  'PASTA',
  'PASTO',
  'PATAN',
  'PATIO',
  'PAUSA',
  'PAUTA',
  'PAVON',
  'PAVOR',
  'PEAJE',
  'PEANA',
  'PECAR',
  'PECHO',
  'PECIO',
  'PEDAL',
  'PEDIR',
  'PEGAR',
  'PEINE',
  'PELAR',
  'PELLA',
  'PELMA',
  'PENAL',
  'PENAR',
  'PENCA',
  'PEÑON',
  'PERAL',
  'PERCA',
  'PERLA',
  'PERLE',
  'PERNO',
  'PERRA',
  'PERRO',
  'PERSA',
  'PESAR',
  'PESCA',
  'PESTE',
  'PETAR',
  'PEZON',
  'PIANO',
  'PIARA',
  'PICAR',
  'PICHI',
  'PICOR',
  'PIEZA',
  'PIFIA',
  'PILAR',
  'PILLO',
  'PILON',
  'PINAR',
  'PINTA',
  'PINTO',
  'PINZA',
  'PIÑON',
  'PIOJO',
  'PIQUE',
  'PIRAR',
  'PISAR',
  'PISCO',
  'PISTA',
  'PISTO',
  'PITAR',
  'PIVOT',
  'PIXEL',
  'PIZCA',
  'PIZZA',
  'PLACA',
  'PLAGA',
  'PLANA',
  'PLANO',
  'PLATA',
  'PLAYA',
  'PLAZA',
  'PLAZO',
  'PLEBE',
  'PLENA',
  'PLENO',
  'PLOMO',
  'PLUMA',
  'POBRE',
  'POCHA',
  'POCHO',
  'PODAL',
  'PODAR',
  'PODER',
  'PODIO',
  'PODRE',
  'POEMA',
  'POETA',
  'POLAR',
  'POLCA',
  'POLEA',
  'POLEN',
  'POLEO',
  'POLLO',
  'POLVO',
  'POMAR',
  'POMEZ',
  'POMPA',
  'PONER',
  'POPAR',
  'PORRA',
  'PORRO',
  'PORTE',
  'POSAR',
  'POSTA',
  'POSTE',
  'POTRA',
  'POTRO',
  'POZAL',
  'PRADO',
  'PRESA',
  'PRESO',
  'PRIMA',
  'PRIMO',
  'PRIOR',
  'PRISA',
  'PROLE',
  'PRONO',
  'PROSA',
  'PUBIS',
  'PUDIN',
  'PUDOR',
  'PUGIL',
  'PUGNA',
  'PUJAR',
  'PULGA',
  'PULIR',
  'PULLA',
  'PULPA',
  'PULPO',
  'PULSO',
  'PUNIR',
  'PUNTA',
  'PUNTO',
  'PUÑAL',
  'PURGA',
  'PURIN',
  'QUEDA',
  'QUEJA',
  'QUESO',
  'QUITA',
  'QUIZA',
  'RABAL',
  'RABEL',
  'RABIA',
  'RACHA',
  'RADAR',
  'RADIO',
  'RADON',
  'RAFIA',
  'RAJAR',
  'RAMAL',
  'RAMPA',
  'RANGO',
  'RAPAR',
  'RAPAZ',
  'RAPTO',
  'RASAR',
  'RASCA',
  'RASGO',
  'RASPA',
  'RATIO',
  'RATON',
  'RAUDO',
  'RAYAR',
  'RAZON',
  'RECIA',
  'RECIO',
  'RECTO',
  'RECUA',
  'REDIL',
  'REGAR',
  'REGIA',
  'REGIO',
  'REGIR',
  'REGLA',
  'REHEN',
  'REINA',
  'REINO',
  'REJON',
  'RELAX',
  'RELOJ',
  'REMAR',
  'RENAL',
  'REÑIR',
  'REOJO',
  'RESTA',
  'RESTO',
  'RETAL',
  'RETAR',
  'RETEN',
  'RETRO',
  'REUMA',
  'REVES',
  'REZAR',
  'RIADA',
  'RIEGO',
  'RIFAR',
  'RIFLE',
  'RIGOR',
  'RILAR',
  'RIMAR',
  'RIMEL',
  'RIÑON',
  'RISCO',
  'RITMO',
  'RIVAL',
  'RIZAR',
  'ROBAR',
  'ROBLE',
  'ROBOT',
  'ROCIO',
  'RODAL',
  'RODAR',
  'RODEO',
  'RODIO',
  'ROGAR',
  'ROJEZ',
  'ROLLO',
  'ROMBO',
  'RONCO',
  'RONDO',
  'ROSAL',
  'ROSCA',
  'ROSCO',
  'ROTAR',
  'ROTOR',
  'ROZAR',
  'RUBIA',
  'RUBIO',
  'RUBLO',
  'RUBOR',
  'RUECA',
  'RUEDA',
  'RUEDO',
  'RUEGO',
  'RUGBY',
  'RUGIR',
  'RUIDO',
  'RUINA',
  'RUMBA',
  'RUMBO',
  'RUMOR',
  'RUPIA',
  'RURAL',
  'SABER',
  'SABIA',
  'SABIO',
  'SABLE',
  'SABOR',
  'SACAR',
  'SACHO',
  'SACRA',
  'SACRO',
  'SAETA',
  'SAGAZ',
  'SAJON',
  'SALAR',
  'SALAZ',
  'SALDO',
  'SALIR',
  'SALMO',
  'SALON',
  'SALSA',
  'SALTO',
  'SALUD',
  'SALVA',
  'SALVE',
  'SALVO',
  'SAMBA',
  'SANAR',
  'SANTA',
  'SANTO',
  'SAQUE',
  'SARGO',
  'SARNA',
  'SARRO',
  'SARTA',
  'SATAN',
  'SATEN',
  'SAUCE',
  'SAUCO',
  'SAUDI',
  'SAUNA',
  'SAVIA',
  'SAZON',
  'SECAR',
  'SECTA',
  'SEDAL',
  'SEDAR',
  'SEGAR',
  'SEGUN',
  'SELLO',
  'SELVA',
  'SEMEN',
  'SENDA',
  'SENIL',
  'SEÑAL',
  'SEÑOR',
  'SEPIA',
  'SERNA',
  'SESEO',
  'SESGO',
  'SEXAR',
  'SEXTA',
  'SEXTO',
  'SIDRA',
  'SIEGA',
  'SIENA',
  'SIESO',
  'SIFON',
  'SIGLA',
  'SIGLO',
  'SIGMA',
  'SIGNO',
  'SILBO',
  'SILLA',
  'SILVA',
  'SIMIL',
  'SIMIO',
  'SIRIO',
  'SISAR',
  'SITIO',
  'SOBAR',
  'SOBRA',
  'SOBRE',
  'SOCIA',
  'SOCIO',
  'SODIO',
  'SOLAR',
  'SOLAZ',
  'SOLEO',
  'SOLER',
  'SOLFA',
  'SOLLA',
  'SONAR',
  'SONDA',
  'SOÑAR',
  'SOPLO',
  'SOPOR',
  'SORBO',
  'SORDA',
  'SORDO',
  'SORNA',
  'STAND',
  'SUAVE',
  'SUBIR',
  'SUCIA',
  'SUCIO',
  'SUCRE',
  'SUDAR',
  'SUDOR',
  'SUECA',
  'SUECO',
  'SUELA',
  'SUELO',
  'SUEÑO',
  'SUERO',
  'SUEVO',
  'SUIZO',
  'SUMAR',
  'SUMIR',
  'SUPER',
  'SURAL',
  'SURCO',
  'SUSTO',
  'SUTIL',
  'TABAL',
  'TABLA',
  'TACHA',
  'TACON',
  'TACTO',
  'TAFIA',
  'TAHUR',
  'TAIGA',
  'TAJAR',
  'TALAR',
  'TALCO',
  'TALIO',
  'TALLA',
  'TALLE',
  'TALLO',
  'TALON',
  'TALUD',
  'TAMIZ',
  'TANDA',
  'TANGO',
  'TANTA',
  'TANTO',
  'TANZA',
  'TAÑER',
  'TAPAR',
  'TAPEO',
  'TAPIA',
  'TAPIZ',
  'TAPON',
  'TARDE',
  'TAROT',
  'TARRO',
  'TARSO',
  'TARTA',
  'TASAR',
  'TASCA',
  'TAURO',
  'TAZON',
  'TEBEO',
  'TECHO',
  'TECLA',
  'TEDIO',
  'TEJER',
  'TEJON',
  'TELAR',
  'TELON',
  'TEMER',
  'TEMOR',
  'TEMPO',
  'TENAZ',
  'TENER',
  'TENIA',
  'TENIS',
  'TENOR',
  'TENSO',
  'TENUE',
  'TEÑIR',
  'TERCA',
  'TERCO',
  'TERMO',
  'TERSA',
  'TERSO',
  'TESIS',
  'TESON',
  'TESTA',
  'TEXTO',
  'TIARA',
  'TIBIA',
  'TIBIO',
  'TIESA',
  'TIESO',
  'TIFON',
  'TIFUS',
  'TIGRE',
  'TILDE',
  'TIMAR',
  'TIMBA',
  'TIMON',
  'TINTA',
  'TINTE',
  'TINTO',
  'TIQUE',
  'TIRAR',
  'TIRON',
  'TIZNA',
  'TIZON',
  'TOCAR',
  'TOCHO',
  'TOLDO',
  'TOLVA',
  'TOMAR',
  'TONAL',
  'TONEL',
  'TONER',
  'TONGO',
  'TONTA',
  'TONTO',
  'TOPAR',
  'TOQUE',
  'TORAL',
  'TORAX',
  'TORDO',
  'TOREO',
  'TORIL',
  'TORMO',
  'TORNO',
  'TORPE',
  'TORSO',
  'TORTA',
  'TORVA',
  'TORVO',
  'TOSCA',
  'TOSCO',
  'TOSER',
  'TOSTA',
  'TOTAL',
  'TOTEM',
  'TRABA',
  'TRACA',
  'TRAER',
  'TRAGO',
  'TRAJE',
  'TRAMA',
  'TRAMO',
  'TRAPO',
  'TRATA',
  'TRATO',
  'TRAZA',
  'TRAZO',
  'TRECE',
  'TRENA',
  'TRETA',
  'TRIAL',
  'TRIAR',
  'TRIBU',
  'TRIGO',
  'TRINO',
  'TRIPA',
  'TROLA',
  'TRONA',
  'TRONO',
  'TROPA',
  'TROTE',
  'TROZO',
  'TRUFA',
  'TRUST',
  'TUCAN',
  'TUMBA',
  'TUMOR',
  'TUNEL',
  'TUPIR',
  'TURBA',
  'TURBO',
  'TURCA',
  'TURCO',
  'TURNO',
  'TUTEO',
  'TUTOR',
  'UFANA',
  'UFANO',
  'UJIER',
  'ULTRA',
  'UNGIR',
  'UNICA',
  'UNICO',
  'UNIDA',
  'UNIDO',
  'UNION',
  'UNTAR',
  'UÑERO',
  'URDIR',
  'URGIR',
  'URICO',
  'USADA',
  'USADO',
  'USTED',
  'USUAL',
  'USURA',
  'UTERO',
  'VACIA',
  'VACIO',
  'VACUO',
  'VAGAR',
  'VAGON',
  'VAINA',
  'VALER',
  'VALGO',
  'VALLA',
  'VALLE',
  'VALOR',
  'VALVA',
  'VAPOR',
  'VARAR',
  'VAREO',
  'VARGA',
  'VARIZ',
  'VARON',
  'VASCA',
  'VASCO',
  'VASTA',
  'VASTO',
  'VATIO',
  'VEDAR',
  'VEJAR',
  'VEJEZ',
  'VELAR',
  'VELLO',
  'VELOZ',
  'VENAL',
  'VENDA',
  'VENIA',
  'VENIR',
  'VENTA',
  'VERAZ',
  'VERBO',
  'VERDE',
  'VERJA',
  'VERSO',
  'VETAR',
  'VIAJE',
  'VICIO',
  'VIEJA',
  'VIEJO',
  'VIGIA',
  'VIGOR',
  'VILLA',
  'VIOLA',
  'VIRAL',
  'VIRAR',
  'VIRGO',
  'VIRIL',
  'VIRUS',
  'VISAR',
  'VISIR',
  'VISON',
  'VISOR',
  'VISTA',
  'VISTO',
  'VITAL',
  'VITOR',
  'VIUDA',
  'VIUDO',
  'VIVAZ',
  'VIVIR',
  'VOCAL',
  'VOLAR',
  'VOSEO',
  'VOTAR',
  'VUELO',
  'VULGO',
  'VULVA',
  'XENON',
  'YACER',
  'YARDA',
  'YELMO',
  'YERMO',
  'YERNO',
  'YOGUR',
  'ZAFAR',
  'ZAFIO',
  'ZAFRA',
  'ZANCO',
  'ZANJA',
  'ZARZA',
  'ZENIT',
  'ZOMBI',
  'ZONAL',
  'ZORRO',
  'ZUECO',
  'ZUMBA',
  'ZURDA',
  'ZURDO',
]
