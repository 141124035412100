export const WORDLE_TITLE = 'El Rosal'

export const WIN_MESSAGES =
  ''
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'About this game'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'No hay suficientes letras.'
export const WORD_NOT_FOUND_MESSAGE = 'La palabra no existe.'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La palabra oculta era ${solution}`
export const NOT_FOUND_MESSAGE =
  'Lo sentimos. Has agotado los intentos para descifrar la palabra oculta.'
export const OVER_TIME_MESSAGE =
  'Lo sentimos. Has agotado el tiempo para descifrar la palabra oculta.'
export const ENTER_TEXT = 'Enviar'
export const DELETE_TEXT = 'Borrar'
export const STATISTICS_TITLE = 'Estadísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribución de aciertos'
export const NEW_WORD_TEXT = 'Pulsa "ENTER" para jugar de nuevo'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Jugadas'
export const SUCCESS_RATE_TEXT = 'Aciertos'
export const CURRENT_STREAK_TEXT = 'Racha'
export const BEST_STREAK_TEXT = 'Mejor Racha'
